import * as React from "react"
import { Box } from "@chakra-ui/react"
import './AlteredCarbon.css';
import Creative from "../../components/Creative"
import backgroundJordan from "./images/alteredCarbon_background.jpg"
import alteredCarbonDragon from "./images/alteredCarbon_elements_dragon.png"
import alteredCarbonLogo from "./images/alteredCarbon_elements_logo.png"
import alteredCarbonTextTop from "./images/alteredCarbon_elements_textTop.png"
import alteredCarbonTextBottom from "./images/alteredCarbon_elements_textBottom.png"
import video from "./images/alteredCarbon_video.mp4"
import SimpleSidebar from "../../components/layout"

const AlteredCarbon = () => (
  <SimpleSidebar>
    <Creative
      className="alteredCarbon"   
      video={video}
      background={backgroundJordan}
      heading="Altered Carbon"
      tags={["interaction" , "animation", "video"]}  
      tagBackground="#e11418"
      tagColor="#000000"
      headingColor="#ffffff"
    >    
       <img src={alteredCarbonDragon} className="alteredCarbon_image alteredCarbon_dragon" /> 
       <img src={alteredCarbonLogo} className="alteredCarbon_image alteredCarbon_logo" /> 
       <img src={alteredCarbonTextTop} className="alteredCarbon_image alteredCarbon_textTop" /> 
       <img src={alteredCarbonTextBottom} className="alteredCarbon_image alteredCarbon_textBottom" /> 
       
    </Creative>
  </SimpleSidebar>
)

export default AlteredCarbon
